html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ivory;
}

canvas {
    touch-action: none;
}

.htmlScreen iframe {
    width: 1024px;
    height: 670px;
    border: none;
    border-radius: 20px;
    background: #000000;
}

.htmlMobileScreen iframe {
    width: 375px;
    height: 812px;
    border: none;
    border-radius: 60px;
    background: #000000;
}
